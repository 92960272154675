<template>
  <b-card>
    <b-card-header><b class="h1">Basic information</b></b-card-header>
    <b-card-body>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group label="*Avatar:" label-cols-md="3">
                <div
                  class="d-flex align-items-center justify-content-center"
                  style="max-width: 200px;"
                >
                  <b-aspect aspect="1:1">
                    <b-img-lazy
                      :src="model.avatar"
                      fluid
                      thumbnail
                      rounded
                      style="position: absolute; max-width: 200px;"
                    />
                    <div
                      class="fade-browse d-flex align-items-center justify-content-center w-100 h-100 rounded"
                    >
                      <b-form-file
                        accept=".jpg, .png, .gif"
                        class="w-100 h-100 d-flex align-items-center justify-content-center img-box"
                        @change="previewFiles"
                      />
                      <b-button
                        variant="outline-primary"
                        class="btn-icon rounded-circle btn-add"
                      >
                        <feather-icon icon="PlusIcon" />
                      </b-button>
                    </div>
                  </b-aspect>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="12">
                  <b-form-group label="*Full name:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Full name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="model.fullname"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Full name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Email:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required"
                    >
                      <b-form-input
                        v-model="model.email"
                        :state="errors.length > 0 ? false : null"
                        type="email"
                        placeholder="Email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*User name:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="User name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="model.username"
                        :state="errors.length > 0 ? false : null"
                        placeholder="User name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Password:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                    >
                      <b-form-input
                        v-model="model.password"
                        type="password"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Confirm Password:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Confirm Password"
                      rules="required"
                    >
                      <b-form-input
                        v-model="model.password_confirm"
                        type="password"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Confirm Password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Birthday:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Birthday"
                      rules="required"
                    >
                      <!-- <label for="example-datepicker">Choose a date</label> -->
                      <b-form-datepicker
                        v-model="model.birthday"
                        :state="errors.length > 0 ? false : null"
                        class="mb-1"
                        placeholder="Birthday"
                        :date-format-options="{
                          day: 'numeric',
                          month: 'numeric',
                          year: 'numeric',
                        }"
                        locale="vi"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Gender:" label-cols-md="3">
                    <div class="d-flex align-items-center">
                      <b-form-radio v-model="model.gender" :value="0">
                        Male
                      </b-form-radio>
                      <b-form-radio
                        v-model="model.gender"
                        :value="1"
                        class="ml-3"
                      >
                        Female
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Is active:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Is active"
                      rules="required"
                    >
                      <v-select
                        v-model="model.is_active"
                        label="text"
                        :reduce="x => x.value"
                        :options="activeOptions"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <!-- Submit -->
            <b-col md="12" class="text-right">
              <b-button
                variant="primary"
                type="submit"
                class="text-right"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCardHeader,
  BCardBody,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormFile,
  BImgLazy,
  BAspect,
  BFormDatepicker,
  BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import Config from '@/@config/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BCardHeader,
    BCardBody,
    BForm,
    BFormFile,
    BImgLazy,
    BAspect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BFormRadio,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      required,
      model: {
        avatar: null,
        fullname: null,
        email: null,
        username: null,
        password: null,
        password_confirm: null,
        birthday: null,
        gender: 0,
        is_active: 1,
      },
      activeOptions: Config.activeOptions,
    }
  },
  methods: {
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const params = {
              template_id: JSON.parse(localStorage.getItem('templateID')).id,
              fullname: this.model.fullname,
              email: this.model.email,
              username: this.model.username,
              password: this.model.password,
              password_confirm: this.model.password_confirm,
              birthday: this.formatDate(this.model.birthday),
              gender: Number(this.model.gender),
              is_active: Number(this.model.is_active),
            }
            if (this.model.avatar) {
              params.avatar = this.model.avatar
            }
            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_URL}/platform_customers`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create Customer success',
                  },
                })
                this.$router.push('/customer/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Login fail',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
